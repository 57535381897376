.tab[data-v-5d0a228c] {
  background: #fff;
  height: 50px;
  padding-left: 10px;
}
.page-search-bar[data-v-5d0a228c] {
  padding: 10px 0px 10px 10px;
  background-color: #fff;
  margin-bottom: 0px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: left;
      -ms-flex-pack: left;
          justify-content: left;
}
.page-search-bar div[data-v-5d0a228c] {
    margin-right: 10px;
}
.page-content[data-v-5d0a228c] {
  height: calc(100% - 140px);
}
@media (max-width: 1700px) {
.page-content[data-v-5d0a228c] {
    height: calc(100% - 220px);
}
}
[data-v-5d0a228c] .el-dropdown {
  cursor: pointer !important;
}
.process-inst-title[data-v-5d0a228c] {
  margin-left: 10px;
  cursor: pointer;
}
.process-inst-title[data-v-5d0a228c]:hover {
    color: #409eff;
    text-decoration: underline;
}
.img[data-v-5d0a228c] {
  position: absolute;
  bottom: 4px;
}
